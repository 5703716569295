.todo-list {
    a {
        text-decoration: none;
        color: #0000cc;
    }
    a:hover {
        text-decoration: underline;
    }
    // .checklist-list{
    //     cursor: pointer;
    // }
    h1 {
        font-weight: 700;
        font-size: 1.5rem;
        text-transform: uppercase;
        letter-spacing: 0.1ch;
        color: #121221;
        margin: 0 0 1em;
        padding: 0 0 1em;
        line-height: 1;
        position: relative;
    }
    h1:after {
        display: block;
        position: absolute;
        content: "";
        height: 2px;
        width: 80px;
        bottom: 0;
        background: #5c3df5;
    }
    input[type="text"] {
        font-weight: 300;
        display: block;
        background: transparent;
        color: #888;
        border: none;
        height: 2rem;
        line-height: 2rem;
        font-size: 1.5rem;
        font-family: inherit;
        width: 100%;
        outline: none;
        transition: 300ms ease;
    }
    input[type="text"]:focus {
        background: #f8f8f8;
    }
    .wrap {
        margin: 0 auto;
        max-width: 720px;
    }
    .app {
        padding: 0 0 3px;
        margin: 0 auto;
        background: #fff;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        overflow: hidden;
        position: relative;
    }
    .app::after {
        display: block;
        position: absolute;
        content: "";
        bottom: 0;
        height: 3px;
        width: 100%;
        border-radius: 0 0 50px 50px;
        background: linear-gradient(to right, #5c3df5, #3d5cf5);
    }
    .task-form {
        margin: 0;
        display: inline-flex;
        width: 100%;
        padding: 1rem;
        overflow: hidden;
        background: #fff;
        border-bottom: 1px solid #dedeed;

        .tag-selector{
            width: 20%;
            margin-right: 10px;
        }
    }
    .task-list {
        padding: 0;
        margin: 0;
        background: #fff;
    }
    .task-list li {
        list-style: none;
        padding: 0.5rem 1rem;
        display: flex;
        flex-wrap: wrap;
        

        .checklist-leftside{
            width: 90%;
        }
        .checklist-rightside{
            width: 10%;
            display: inline-flex;
        }
        

    }
    .task-controls {
        padding: 0.5rem 1rem;
    }
    .task-controls span {
        float: right;
        height: 2rem;
        line-height: 2rem;
        color: #9b9ba6;
    }
    .task-controls {
        button {
            cursor: pointer;
            display: inline-block;
            outline: none;
            background: transparent;
            font-family: inherit;
            font-size: 0.8rem;
            border: none;
            padding: 0.5rem 1rem;
            border-radius: 100px;
            margin-right: 0.5rem;
            background-color: transparent;
            transition: 150ms ease;
            box-shadow: inset 0 0 0 1px #eeeef6;
            color: #6f6f77;
            font-weight: 600;
            margin-bottom: 0.5rem;
        }
        button:hover {
            box-shadow: none;
            background-color: #eeeef6;
        }
        button:hover i {
            color: #e14;
        }
        button:active,
        button.btn-active {
            background-color: #eeeef6;
            box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
        }

        border-top: 1px solid #f0eef6;
        margin-top: 10px;
    }
    .modal-wrap {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .modal {
        background-color: #fff;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    }
    .task-tags {
        padding: 0.5rem 1rem;
        border-bottom: 1px solid #f0eef6;

        button {
            cursor: pointer;
            display: inline-block;
            outline: none;
            background: transparent;
            font-family: inherit;
            font-size: 0.8rem;
            border: none;
            padding: 0.5rem 1rem;
            border-radius: 100px;
            margin-right: 0.5rem;
            background-color: transparent;
            transition: 150ms ease;
            box-shadow: inset 0 0 0 1px #eeeef6;
            color: #6f6f77;
            font-weight: 600;
            margin-bottom: 0.5rem;
        }
        button:hover {
            box-shadow: none;
            background-color: #eeeef6;
        }
        button:hover i {
            color: #e14;
        }
        button:active,
        button.btn-active {
            background-color: #eeeef6;
            box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
        }
        > span {
            color: #9b9ba6;
        }
        button span {
            border-radius: 100px;
            height: 0.75em;
            width: 0.75em;
            display: inline-block;
            margin-right: 0.5rem;
            vertical-align: baseline;
        }
    }
    // .task-tags > span {
    //     color: #9b9ba6;
    // }
    // .task-tags button {
    //     cursor: pointer;
    //     display: inline-block;
    //     outline: none;
    //     background: transparent;
    //     font-family: inherit;
    //     font-size: 0.8rem;
    //     border: none;
    //     border-radius: 100px;
    //     padding: 0.1rem 0.5rem 0.1rem 0.25rem;
    //     margin-right: 0.5rem;
    //     transition: 300ms ease;
    //     font-weight: 600;
    //     color: #6f6f77;
    // }
    // .task-tags button span {
    //     border-radius: 100px;
    //     height: 0.75em;
    //     width: 0.75em;
    //     display: inline-block;
    //     margin-right: 0.5rem;
    //     vertical-align: baseline;
    // }
    .task-input {
        width: calc(92%);
        float: left;
        background: transparent;
    }
    .task-add-button {
        float: right;
    }
    .task-add-button {
        cursor: pointer;
        display: inline-block;
        outline: none;
        font-family: inherit;
        font-size: 0.8rem;
        border: none;
        width: 2rem;
        height: 2rem;
        border-radius: 50px;
        padding: 0;
        color: #5959a6;
        font-size: 2rem;
        background: transparent;
        transition: 150ms ease;
    }
    .task-add-button svg {
        display: block;
        stroke: #555;
        stroke-width: 2px;
        stroke-linecap: round;
        width: 100%;
        height: 100%;
        stroke: #5c3df5;
    }
    .task-add-button:hover {
        background: #f2f2f2;
    }
    .task-add-button:active {
        box-shadow: inset 0 2px 10px rgba(0, 0, 0, 0.1);
        background: #e6e6e6;
    }
    .task-item-edit {
        cursor: pointer;
        display: inline-block;
        outline: none;
        font-family: inherit;
        font-size: 0.8rem;
        position: relative;
        //   float: right;
        display: block;
        height: 2rem;
        width: 2rem;
        border: none;
        padding: 0;
        background: transparent;
        transition: 300ms ease;
    }
    .task-item-edit svg {
        display: block;
        stroke: #555;
        stroke-width: 2px;
        stroke-linecap: round;
        width: 100%;
        height: 100%;
        stroke: #dedeed;
    }
    .task-item-edit:hover svg {
        stroke: #e14;
    }
    .task-item-edit::before {
        transform: rotate(-45deg);
    }
    .task-item-edit::after {
        transform: rotate(45deg);
    }
    
    .task-item-remove {
        cursor: pointer;
        display: inline-block;
        outline: none;
        font-family: inherit;
        font-size: 0.8rem;
        position: relative;
        //   float: right;
        display: block;
        height: 2rem;
        width: 2rem;
        border: none;
        padding: 0;
        background: transparent;
        transition: 300ms ease;
    }
    .task-item-remove svg {
        display: block;
        stroke: #555;
        stroke-width: 2px;
        stroke-linecap: round;
        width: 100%;
        height: 100%;
        stroke: #dedeed;
    }
    .task-item-remove:hover svg {
        stroke: #e14;
    }
    .task-item-remove::before {
        transform: rotate(-45deg);
    }
    .task-item-remove::after {
        transform: rotate(45deg);
    }
    .task-item-checked {
        color: #888;
    }
    input[type="checkbox"] {
        display: none;
        position: relative;
    }
    input[type="checkbox"] + label {
        padding-left: 2rem;
        position: relative;
        transition: color 150ms ease;
        height: 2rem;
        line-height: 2rem;
        cursor: pointer;
    }
    input[type="checkbox"] + label .task-strike {
        position: absolute;
        display: block;
        height: 2px;
        right: 0;
        left: 2rem;
        top: 50%;
        background: #5c3df5;
        transition: 450ms ease;
        transform: scaleX(0);
        transform-origin: left;
    }
    input[type="checkbox"] + label::before,
    input[type="checkbox"] + label::after {
        display: block;
        position: absolute;
        content: "";
    }
    input[type="checkbox"] + label::before {
        left: 0;
        height: 1.25rem;
        width: 1.25rem;
        margin-right: 0.5rem;
        top: calc((100% - 1.25rem) / 2);
        border: 1px solid #dedeed;
        border-radius: 50px;
    }
    input[type="checkbox"] + label::after {
        left: 0.35rem;
        top: 0.75rem;
        height: 0.35714286rem;
        width: 0.625rem;
        margin-right: 0.5rem;
        border-left: 3px solid #5c3df5;
        border-bottom: 3px solid #5c3df5;
        transform: rotate(-45deg) scale(0.5);
        transform-origin: 50% 50%;
        opacity: 0;
        transition: 150ms ease-in-out;
    }
    input[type="checkbox"]:checked + label {
        color: #ccc;
    }
    input[type="checkbox"]:checked + label .task-strike {
        transform: scaleX(1);
    }
    input[type="checkbox"]:checked + label::after {
        opacity: 1;
        transform: rotate(-45deg) scale(1);
    }
    .footer {
        padding: 5rem 0;
        text-align: center;
        color: #888;
        font-size: 0.9rem;
    }
    .expired-label{
        color: #CCC;
    }
}


.task-expire-info{
    font-size: 0.75rem;
    font-style: italic;
    float: right;
    height: 2rem;
    line-height: 2rem;
}
