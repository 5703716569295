@mixin Shimmer {
  & {
    background-color: color(tertiary-grey);
    animation-name: anim-shimmer;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 500px 100%;
    color: transparent;
    border-radius: 100px;
  }
}

@keyframes anim-shimmer {
  from {
    background-position: -500px 0;
  }
  to {
    background-position: 500px 0;
  }
}

@mixin clearFix() {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &:after {
    display: block;
    clear: both;
    visibility: hidden;
  }
  & {
    *zoom: 1;
  }
}
