$header-background: hsla(188, 78%, 41%, 0.5);
$header-background-dark: #001529;

.site-header{
    position: relative;
    height: 0px;
    z-index: 99;
}

.headers {
    height: 0px;
    position: absolute;
    width: 100%;
    float: right;
    height: 76px;
    box-shadow: 1px 1px 10px 1px #CCC;
    justify-content: flex-end;
    border-bottom: 4px solid hsla(188, 78%, 41%, 1);
    //background-color: $header-background;
    background: #17a4ba;
    background: linear-gradient(90deg, rgba(17, 194, 201, 1) 0%, rgba(23, 180, 186, 1) 40%, rgba(30, 146, 180, 1) 90%);
    overflow: hidden;

    .header-menu {
        position: relative;
        width: 300px;
        float: right;
        border: none !important;
        padding: 15px !important;
        padding-bottom: 0px !important;
        padding-right: 50px !important;
        background-color: #00152900;
    }

    .anticon{
        position: relative;
        padding-left: 10px;
        top: -2.5px;
    }
    .ant-menu-item{
        padding: 0px !important;
    }
    .ant-menu-item:hover{
        color: #001529 !important;
    }
    .ant-menu-submenu{
        margin: 0px !important;
        color:#001529;
        border: none;
    }
    .menu-submenu:hover{
        color:#CCC;
    }

    .ant-menu-title-content, .ant-menu-item-icon{
        color:#001529;
    }
    .ant-menu-horizontal .ant-menu-submenu{
        transition: none !important;
    }

    /** border on botton of antd meanu **/
    .ant-menu-submenu:hover::after, .ant-menu-submenu::after{
        border:none !important;
        content: none !important;
    }

    

    // .ant-menu-title-content:hover{
    //     color:#CCC;
    // }
    // .ant-menu-item-icon:hover{
    //     color:#CCC;
    //     transition: none !important;
    // }
    
}

.headers-dark {
    position: absolute;
    width: 100%;
    float: right;
    height: 76px;
    box-shadow: 1px 1px 10px 1px hsl(209, 100%, 20%);
    justify-content: flex-end;
    border-bottom: 4px solid hsla(188, 78%, 41%, 0.5);
    background-color: $header-background-dark;
    transition: all 0.3s;

    .header-menu-dark {
        float: right;
        border: none !important;
        padding: 15px !important;
        padding-bottom: 0px !important;
        padding-right: 50px !important;
        background-color: $header-background-dark;
        transition: all 0.3s;
    }

    .anticon{
        position: relative;
        padding-left: 10px;
        top: -2.5px;
    }
    .ant-menu-item{
        padding: 0px !important;
    }
    .ant-menu-submenu{
        margin: 0px !important;
    }
    
    .ant-menu-item-selected{
        color: #f0f0f0 !important;
        background-color: $header-background-dark !important;
    }
    
}

.notification-badge {
    //color: #f74d4d !important;
    border-radius: 50% !important;
    position: absolute;
    right: -5px;

}
