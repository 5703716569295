@import "~styles/main";

.ReactNotifications__ContentWithIcon {
    @include clearFix();
    font-size: 14px;

    .ReactNotifications__Content{
        float: left;
        font-weight: 600;
    }

    .ReactNotifications__Icon {
        float: right;
    }

}