@import '~styles/main.scss';


.site-navigation{
    position: relative;
    top:-25px;

    @media (max-width:992px) {
        position: absolute;
        top:0px
    }
    .ant-menu{
        margin-top: 80px !important;
    }
    .ant-menu-sub{
        margin-top: 0px !important;
    }
}

.sidebar-menu-light{
    float:left;
    position:relative;
    z-index: 100;
    height: 100vh;
    box-shadow: 1px 1px 1px 1px #f0f0f0;
}

.sidebar-menu-dark{
    float:left;
    position:relative;
    z-index: 100;
    height: 100vh;
    box-shadow: 1px 1px 1px 1px hsl(209, 100%, 20%);
}

.sidebar-title{
    position: fixed;
    padding: 40px;
    height: 80px;
}

.sidebar-logo{
    position: relative;
    top: -30px;
    max-width: 150px;
    max-height: 75px;
}

.sidebar-icon{
    position: relative;
    top: -22.5px;
    left: -15px;
    max-width: 50px;
    max-height: 45px;
}

.ant-tooltip{
    position: fixed !important;
}

.ant-layout-sider-light .ant-layout-sider-trigger:hover{
    background-color: hsla(188, 78%, 41%, 0.2);
}
.ant-layout-sider-light .ant-layout-sider-trigger{
    box-shadow: inset 1px 1px 10px 0.5px hsla(0, 0%, 94%, 1);
}

a:hover{
    text-decoration: none;
}

.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
    width: 80px !important;
}


// .ant-menu-item-selected{
//     background-color: hsla(188, 78%, 41%, 0.2) !important;
//     color: hsla(188, 78%, 41%, 1) !important;
// }

// .ant-menu-submenu-selected{
//     color: hsla(188, 78%, 41%, 1) !important;
// }

// .ant-menu-item-active{
//     color: hsla(188, 78%, 41%, 1) !important;
// }

// .ant-menu-submenu:hover{
//     color: hsla(188, 78%, 41%, 1) !important;
// }
