@import "~styles/utils/_variables";


//  The dark variation of the colotr is used in the border


$default: color(primary-blue);
$default_dark: color(primary-blue);

$success: color(success-green);
$success_dark: color(success-green);

$danger: color(error-red);
$danger_dark: color(error-red);

$info: color(primary-blue);
$info_dark: color(primary-blue);

$warning: #eab000;
$warning_dark: #ce9c09;

$awesome: #685dc3;
$awesome_dark: #4c3fb1;

$default_timer: #007bff;
$default_timer_filler: #fff;

$success_timer: #28a745;
$success_timer_filler: #fff;

$danger_timer: #dc3545;
$danger_timer_filler: #fff;

$info_timer: #17a2b8;
$info_timer_filler: #fff;

$warning_timer: #eab000;
$warning_timer_filler: #fff;

$awesome_timer: #685dc3;
$awesome_timer_filler: #fff;