@import "_variables.scss";

.notification-default {
  background-color: $default;
  border-left: 8px solid $default_dark;

  .timer {
    background-color: $default_timer;
  }
  .timer-filler {
    background-color: $default_timer_filler;
  }
  .notification-close {
    background-color: $default;
  }
}

.notification-success {
  background-color: $success;
  border-left: 8px solid $success_dark;

  .timer {
    background-color: $success_timer;
  }
  .timer-filler {
    background-color: $success_timer_filler;
  }
  .notification-close {
    background-color: $success;
  }
}

.notification-danger {
  background-color: $danger;
  border-left: 8px solid $danger_dark;

  .timer {
    background-color: $danger_timer;
  }
  .timer-filler {
    background-color: $danger_timer_filler;
  }
  .notification-close {
    background-color: $danger;
  }
}

.notification-info {
  background-color: $info;
  border-left: 8px solid $info_dark;

  .timer {
    background-color: $info_timer;
  }
  .timer-filler {
    background-color: $info_timer_filler;
  }
  .notification-close {
    background-color: $info;
  }
}

.notification-warning {
  background-color: $warning;
  border-left: 8px solid $warning_dark;

  .timer {
    background-color: $warning_timer;
  }
  .timer-filler {
    background-color: $warning_timer_filler;
  }
  .notification-close {
    background-color: $warning;
  }
}

.notification-awesome {
  background-color: $awesome;
  border-left: 8px solid $awesome_dark;

  .timer {
    background-color: $awesome_timer;
  }
  .timer-filler {
    background-color: $awesome_timer_filler;
  }
  .notification-close {
    background-color: $awesome;
  }
}
